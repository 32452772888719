<template>
  <base-section id="k-d-a-blog-details" class="white" space="80">
    <v-container class="pa-0 px-7">
      <v-row justify="space-around">
        <v-col cols="12" lg="9" xl="7">
          <v-row no-gutters>
            <v-col v-if="m_objSelectedBlog.strLabel" cols="12">
              <v-chip
                :color="m_objSelectedBlog.strLabelColor"
                :style="{ backgroundColor: `${m_objSelectedBlog.hexLabelColor} !important` }"
                :class="`kda-ts-${g_bLowerBr ? '12' : '16'}pt nunito wt-semibold white--text`"
                :small="g_bLowerBr"
              >
                {{ m_objSelectedBlog.strLabel }}
              </v-chip>
            </v-col>

            <v-col cols="12" class="mt-3 mt-md-8">
              <v-row no-gutters :style="{ gap: g_bLowerBr ? '8px' : '30px' }">
                <v-col cols="12">
                  <h1
                    :class="`${g_bLowerBr ? 'kda-ts-24pt' : 'kda-ts-36pt'} nunito wt-extrabold`"
                    style="line-height: 120%"
                  >
                    {{ m_objSelectedBlog.strTitle }}
                  </h1>
                </v-col>

                <v-col cols="12">
                  <base-img
                    :src="m_objSelectedBlog.imgSrc"
                    :max-height="g_bLowestBr ? 128 : 428"
                    :contain="!g_bLowestBr"
                    position="left"
                  />
                </v-col>

                <v-col cols="12">
                  <p
                    id="content"
                    :class="`${g_bLowerBr ? 'kda-ts-12pt' : 'kda-ts-16pt'} nunito ln-170`"
                    v-html="m_strBlogDescription"
                  ></p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>

        <v-col v-if="!g_bBaseBr" cols="3">
          <v-row no-gutters style="gap: 14px; background: #f9f9f9" class="ml-6 px-6 py-4">
            <v-col cols="12">
              <h4 class="kda-ts-20pt nunito wt-extrabold">{{ m_strRelatedTitle }}</h4>
            </v-col>

            <v-col
              v-for="(trending, index) in m_arrTrendingBlogs"
              :key="`list-of-trending-blog-${index}`"
              cols="12"
            >
              <k-d-a-blog-card
                small
                :str-title="trending.strTitle"
                :str-description="trending.strDescription"
                :str-label="trending.strLabel"
                :hex-label-color="trending.hexLabelColor"
                :img-src="trending.imgSrc"
                @info="
                  $router.push({
                    name: 'KDABlogDetails',
                    params: { strHashedID: trending.strID ?? 'dummy' }
                  })
                "
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col v-else cols="12">
          <div class="d-flex justify-end align-center">
            <p class="kda-ts-12pt wt-bold nunito">{{ m_strButtonMore }}</p>

            <v-btn icon elevation="0" class="transparent ml-4">
              <v-icon color="black">mdi-arrow-right</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';
import KDASelectedBlog from './extension/KDASelectedBlog';

export default {
  name: 'KDABlogDetails',

  components: {
    KDABlogCard: () => import('@/components/KDABlogCard')
  },

  extends: KDASelectedBlog,

  mixins: [BaseBreakpoint],

  data() {
    return {
      page: 1
    };
  },

  computed: {
    m_arrQueries() {
      return ['Engineer', 'Designer', 'Sales', 'Business'];
    },
    m_objDesc() {
      return {
        htmlText:
          'Kami adalah sekumpulan programmer, designers, dan project manager yang tersebar di seluruh penjuru Indonesia.<br/><br/>Karena kami tahu di KeDA Tech, Anda bisa beekerja dari mana saja dan yang dapat kami pastikan adalah kesungguhan hati kami dalam memberikan teknologi yang tepat untuk bisnis para client. ',
        strComponent: 'p'
      };
    },
    m_strButtonMore() {
      return this.$vuetify.lang.t('$vuetify.kda.blog.details.more');
    },
    m_strRelatedTitle() {
      return this.$vuetify.lang.t('$vuetify.kda.blog.details.related');
    },
    m_strBlogDescription() {
      return this.m_objSelectedBlog.strDescription.replaceAll(
        '<br/><br/>',
        '<br/><div class="par-spacer"></div>'
      );
    }
  }
};
</script>

<style scoped lang="scss">
#content::v-deep {
  .par-spacer {
    height: 0.75rem;
  }
}
</style>
