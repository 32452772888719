<script>
import APIRequests from '@/mixins/api-requests';

export default {
  name: 'KDABlogList',

  mixins: [APIRequests],

  data: () => ({
    m_strNoBlog: 'There are no job blog at the moment. Please come back at another time'
  }),

  computed: {
    m_arrBlogs() {
      if (this.g_arrBlogs?.length) {
        return this.g_arrBlogs;
      }

      // TODO: hardcode
      const result = [];
      for (let i = 1; i <= 5; i++) {
        result.push({
          strID: i.toString(),
          strTitle: this.$vuetify.lang.t(`$vuetify.kda.blog.content${i}.title`),
          strDescription: this.$vuetify.lang.t(`$vuetify.kda.blog.content${i}.desc`),
          imgSrc: require(`@/assets/photos/blog-content-${i}.jpg`)
        });
      }

      return result;
    },
    m_arrTrendingBlogs() {
      return this.m_arrBlogs.slice(0, 4);
    }
  },

  mounted() {
    // TODO: create method for API request
    // this.getBlogList();
  }
};
</script>
