<script>
import APIRequests from '@/mixins/api-requests';
import KDABlogList from './KDABlogList';

export default {
  name: 'KDASelectedBlog',

  extends: KDABlogList,

  mixins: [APIRequests],

  data: () => ({
    m_strBlogNotFound: 'The selected blog is no longer available'
  }),

  computed: {
    m_objSelectedBlog() {
      if (this.g_objSelectedBlog && 'strID' in this.g_objSelectedBlog) {
        return this.g_objSelectedBlog;
      }

      // TODO: dummy
      return this.m_arrBlogs[parseInt(this.$route.params.strHashedID) - 1];
    }
  },

  mounted() {
    // TODO: create method for API request
    // this.getBlogByID(this.$route.params.strHashedID);
  }
};
</script>
